import { useLocation } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import FormSteps from "./FormSteps";
import Icon from './Icon';
import wifiIcon from '../assets/icons/wifi-white.svg';
import { useTranslation } from 'react-i18next';
import Button from './Button';

const Square = styled.div`
  width: 20px;
  height: 20px;
  padding: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  background-color: rgba(255, 255, 255, 0.3); 
  
  &:last-child {
    background-color: #fff;
  }
`;

const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Dash = styled.div`
  height: 3px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 10px;
  margin-right: 10px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.primary}70;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
`;

const PopupContent = styled.div`
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
  z-index: 101; 
  background: ${props => props.theme.colors.primary};
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;

 
`;

const Title = styled.div`
  font-size: 2rem;
  color: white;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Text = styled.div`
  font-size: 1.5rem;
  color: #FFF;
  text-align: center;
`;

const MobileOnly = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const ButtonLink = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;


const Popup = ({ onClose }) => {
  const location = useLocation();
  const time = location.state?.remainingTime; 
  const { t } = useTranslation();
  document.cookie = location.state?.cookies;


  
  return (
    <Overlay>
      <PopupContent>
      <CloseButton onClick={onClose}>X</CloseButton>

        <MobileOnly>
          <Steps>
            <Square>1</Square>
            <Dash />
            <Square>2</Square>
            <Dash />
            <Square><Icon src={wifiIcon} /></Square>
          </Steps>
        </MobileOnly>

        <Title>{t('connected')}</Title>
        <Text>{t('freeWifiAvailability', { time: time })}</Text>
 
      </PopupContent>
    </Overlay>
  );
};

export default Popup;

