import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import TaxiIcon from "../assets/icons/car.svg";
import ShowMap from "../components/ShowMap";
import MapPopup from "../components/MapPopup";
import busMap from "../assets/images/busmap.png";
import taxiBackground from "../assets/images/taxiBackground.svg";
import Search from '../components/SearchInput';
import { useTranslation } from 'react-i18next';
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';
import { getMap } from '../services/mapService';
import CarParkTable from "../components/CarParkTable";


const Content = styled.div`
height: 50vh;
display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 100;
  background-image: url('${taxiBackground}');
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-right: 2rem;
  }
`;

const MapContent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MapImage = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
  

  width: 40%;
`;

const Flex = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 100%;
`;

function CarPark() {
  const { showPopupUser } = useHotspotUserInfo();
  const [CarParkImageUrl, setCarParkImageUrl] = useState('');
  const [showMapContent, setShowMapContent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCarParkImage = async () => {
      try {
        const data = await getMap('parking'); 
        setCarParkImageUrl(data.data.map); 
      } catch (error) {
        console.error('Error fetching CarPark image:', error);
      }
    };
    

    fetchCarParkImage();
  }, []); 


  const handleToggleMapContent = () => {
    setShowMapContent(!showMapContent);
  };

  return (
    <HomeContainer>
      <Flex>
        <Container>
        <Title icon={TaxiIcon} text="carPark" />
          <Content>
            {!showMapContent ? (
              <Container>
                  <CarParkTable />
    
              </Container>
            ) : (
              ""
            )}
            <MapContent onClick={handleToggleMapContent}>
              {showMapContent ? (
                <MapPopup imageUrl={CarParkImageUrl} onClose={handleToggleMapContent} />
              ) : (
                <ShowMap text={t('howToFindCarParkStop')} imageUrl="path/to/CarPark-image.jpg" />
              )}
            </MapContent>
          </Content>
        </Container>
        <MapImage src={CarParkImageUrl} />
      </Flex>
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default CarPark;


