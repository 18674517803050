import axios from 'axios';
import { HOTSPOT_USER_ENDPOINT, HOTSPOT_USER_REMAINING_ENDPOINT } from './endpoints';

export const registerHotspotUser = async (userData) => {
  return axios.put(HOTSPOT_USER_ENDPOINT, userData);
};

export const getInfoForHotspotUser = async (mac) => {
  try {
    const formattedEndpoint = HOTSPOT_USER_REMAINING_ENDPOINT.replace('%(mac)s', mac);
    const response = await axios.get(formattedEndpoint);

    if (response) {
   

      // Store user session when they connect
      sessionStorage.setItem("hotspotUser", JSON.stringify(response.data));

      // Attach event listeners to clear session when portal is closed
      attachSessionClearListeners();
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching info for user:', error);
    throw error;
  }
};

const clearHotspotSession = () => {
  sessionStorage.removeItem("hotspotUser");
};

// Attach event listeners for clearing session on tab close or inactivity
const attachSessionClearListeners = () => {
  const clearSession = () => {
    clearHotspotSession();
  };

  window.addEventListener("beforeunload", clearSession);
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      clearSession();
    }
  });
};
